import 'jquery-smooth-scroll'
import 'jquery.easing';
// import { isMobile } from "./isMobile";

/*
 *
 * ----------------------------------------------- */
(function () {
  if (typeof $.smoothScroll !== 'function') {
    return false
  }

  $(window).on('load', function () {
    var reSmooth = /^#sm-/
    var offset = -100 // Adjust offset as needed

    // Check if location.hash exists
    if (location.hash) {
      var id

      // Handle custom smooth scrolling for #sm-* hashes
      if (reSmooth.test(location.hash)) {
        id = '#' + location.hash.replace(reSmooth, '')
      } else if (location.hash === '#portfolio') {
        id = location.hash
      }

      // If a valid ID is found, perform smooth scroll
      if (id && $(id).length) {
        $.smoothScroll({
          scrollTarget: id,
          offset: offset,
          easing: 'easeInOutCubic'
        })
      }
    }
  })
})()

/*
 * -----------------------------------------------
 */
// jQuery Smooth Scroll - v2.2.0 - 2017-05-05
// https://github.com/kswedberg/jquery-smooth-scroll
jQuery(function ($) {
  $('[data-sm]').smoothScroll({
    offset: -90
  })
})
